import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'

export default {
  computed: {
    hasDropdownLoadedCommonService () {
      return this.$store.state.ExternalUserService.lrcpnPanel.commonObj.hasDropdownLoaded
    }
  },
  watch: {
    hasDropdownLoadedCommonService: function (newValue) {
      if (!newValue) {
        this.loadCommonDropdown()
      }
    }
  },
  created () {
    this.loadCommonDropdown()
  },
  methods: {
    loadCommonDropdown () {
      RestApi.getData(lrcpnServiceBaseUrl, 'lrcpn-service-external-dropdowns', null).then(response => {
        if (response.success) {
          this.$store.dispatch('ExternalUserService/mutateCommonObjLrcpnPanel', {
            hasDropdownLoaded: true,
            masterAcidNameList: response.data.masterAcidNameList,
            paymentTypeList: response.data.masterPaymentInfoList
          })
          this.$store.dispatch('ExternalUserService/localizeDropdowneLrcpnPanel', { value: this.$i18n.locale })
        }
      })
    }
  }
}
